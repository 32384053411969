import { faker } from '@faker-js/faker'
import EnvComponentMock from './EnvComponent'

const { datatype, random } = faker

export default class EnvironmentMock {
  constructor ({
    canonical = random.word(),
    name = random.word(),
    id = datatype.number(),
    cloud_provider = 'aws',
    // See parseProjectEnvironments in project store.
    cloud_provider_canonical = cloud_provider,
    cloud_provider_name = cloud_provider,
    use_case = 'aws',
    created_at = 1555851669,
    updated_at = 1565834587,
    components = ['Frontend', 'Backend', 'Database']
      .map((name) => new EnvComponentMock(name)),
  } = {}) {
    Object.assign(this, {
      canonical,
      name,
      id,
      cloud_provider,
      cloud_provider_canonical,
      cloud_provider_name,
      use_case,
      created_at,
      updated_at,
      components,
    })
  }
}
