import { faker } from '@faker-js/faker'
import StackMock from './Stack'

export default class EnvComponentMock {
  constructor (name, {
    id = faker.datatype.number(),
    canonical = _.kebabCase(name),
    description = 'This is a component description.',
    useCase = 'default',
    serviceCatalog = new StackMock(),
    createdAt = 1503225026,
    updatedAt = 1503335026,
  } = {}) {
    Object.assign(this, {
      id,
      canonical,
      name,
      description,
      service_catalog: _.cloneDeep(serviceCatalog),
      use_case: useCase,
      created_at: createdAt,
      updated_at: updatedAt,
    })
  }
}
