import { faker } from '@faker-js/faker'
import EnvComponentMock from './EnvComponent'
import EnvironmentMock from './Environment'
import MemberMock from './Member'
import StackMock from './Stack'

export default class ProjectMock {
  constructor (name, {
    canonical = _.kebabCase(name),
    color = 'default',
    configRepositoryId,
    configRepositoryCanonical,
    icon = 'extension',
    id = faker.datatype.number(),
    infraImportStatus,
    environments = [
      new EnvironmentMock({
        canonical: 'dev',
        name: 'Dev',
        components: ['Frontend', 'Backend', 'Database']
          .map((name) => new EnvComponentMock(name)),
      }),
      new EnvironmentMock({
        canonical: 'staging',
        name: 'Staging',
        components: ['Docs', 'CLI']
          .map((name) => new EnvComponentMock(name)),
      }),
      new EnvironmentMock({
        canonical: 'prod',
        name: 'Prod',
        components: ['ElasticSearch', 'S3 Bucket']
          .map((name) => new EnvComponentMock(name)),
      }),
      new EnvironmentMock({
        canonical: 'test',
        components: [],
      }),
      new EnvironmentMock({
        canonical: 'qa',
        components: [],
      }),
    ],
    owner = new MemberMock(),
    description = 'this is a project description',
    serviceCatalogRef = 'seraf:stack-dummy',
    serviceCatalog = new StackMock({ ref: serviceCatalogRef }),
    teamCanonical = null,
    createdAt = 1603807906,
    updatedAt = 1610702464,
    favorite,
  } = {}) {
    Object.assign(this, {
      canonical,
      color,
      description,
      environments,
      icon,
      id,
      name,
      owner,
      created_at: createdAt,
      updated_at: updatedAt,
      service_catalog: _.cloneDeep(serviceCatalog),
    })
    if (_.isBoolean(favorite)) this.favorite = favorite
    if (teamCanonical) this.team_canonical = teamCanonical
    if (configRepositoryId) this.config_repository_id = configRepositoryId
    if (configRepositoryCanonical) this.config_repository_canonical = configRepositoryCanonical
    if (infraImportStatus) this.service_catalog.import_status = infraImportStatus
  }
}
